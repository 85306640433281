@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;
}

@layer components {
	.br500 {
		@apply border-2 border-red-500;
	}
}


.advanceStudioFrame {
	background-image: url('../../public/frame.png');
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: cover;
}

.hideScrollBar::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hideScrollBar {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

.PhoneInputInput {
	background-color: transparent !important;
}

.PhoneInputInput:focus {
	outline: none !important;
}

.PhoneInputCountrySelectArrow {
	display: none !important;
}

.PhoneInputCountryIcon--border {
	border-radius: 50% !important;
	box-shadow: none !important;
	width: 18px !important;
	height: 20px !important;
	background-color: transparent !important;
	overflow: hidden !important;
}

.PhoneInputCountryIconImg,
.PhoneInputCountrySelect {
	border-radius: 50% !important;
	width: 100% !important;
	height: 100% !important;
	scale: 1.6 !important;
}
